import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import { getUser, isLoggedIn } from "../services/auth"


const IndexPage = () => {
    const data = useStaticQuery(query);

    return (
        <Layout seo={data.strapiHomepage.seo}>
            <h1>Hello {isLoggedIn() ? getUser().name : "world"}!</h1>
            <div className="uk-section">
                <div className="uk-container uk-container-large">
                    <h1>{data.strapiHomepage.hero.title}</h1>
                </div>
            </div>
            <div className="uk-section homepage-callout">
                <div className="uk-container uk-container-large container-flex row">
                    <div className="uk-container container-flex column">
                        <img src={data.strapiHomepage.homepage_cta.Image.localFile.publicURL} />
                    </div>
                    <div className="uk-container container-flex column">
                        <h2>{data.strapiHomepage.homepage_cta.Title}</h2>
                        <p>{data.strapiHomepage.homepage_cta.Text}</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      homepage_cta {
        Image {
          localFile {
            publicURL
          }
        }
        Title
        Text
      }
    }
  }
`;

export default IndexPage;
